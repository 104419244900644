import { useEffect, useState } from "react";
import Header from "src/components/Header";
import HomeTable from "src/components/HomeTable";
import Transaction from "src/components/Transaction";
import "./index.scss";
import Pusher from "pusher-js";
import { IModalInfo } from "src/models/home";
import { useQuery } from "react-query";
import { Home } from "../../services/Home";
import { QueryKey } from "src/constants/query";

const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<IModalInfo | null>(null);
  const businessInfo = useQuery([QueryKey.BUSINESSES_INFO], () => {
    return Home.getBusinessInfo();
  });

  useEffect(() => {
    if (businessInfo.data?.id) {
      const pusher = new Pusher("f3570747bee4f7b3a1b0", {
        cluster: "eu",
      });
      const channel = pusher.subscribe(
        `bussiness_payments_channel_${businessInfo.data?.id}`
      );
      channel.bind("child_payment", function (data: any) {
        setModalInfo(data);
        setIsModalOpen(true);
      });
    }
  }, [businessInfo.data?.id]);

  const openLink = () => {
    const win = window.open(
      "https://yangi.payme.io/system/login/auth?lang=he-il",
      "_blank"
    );
    win?.focus();
  };

  return (
    <div className="home">
      <Header />
      <div className="home__wrapper">
        <div className="home__content">
          <div className="home__top-bar">
            <button
              type="button"
              className="home__credit-interface"
              onClick={() => openLink()}
            >
              עבור לממשק זיכויים
            </button>
            <h3 className="home__title">עסקאות אחרונות</h3>
          </div>

          <HomeTable />
        </div>
      </div>

      {isModalOpen && (
        <Transaction
          close={() => setIsModalOpen(false)}
          modalInfo={modalInfo}
        />
      )}
    </div>
  );
};

export default HomePage;
