import { transactionStatus } from "src/constants/main";
import "./index.scss";
import { ReactComponent as ApprovedIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as CancelledIcon } from "../../assets/icons/disabled-minus.svg";
import cn from "classnames";

interface Props {
  status: string;
  id: number;
  name: string;
  sum: number;
  date: string;
}

const dateOptions: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
};

const TableRow: React.FC<Props> = ({ status, id, name, sum, date }) => {
  const getFormattedDate = (date: string) => {
    return new Date(date)
      .toLocaleDateString("en-GB", dateOptions)
      .replaceAll(",", "");
  };

  const getStatusDetails = () => {
    switch (status) {
      case transactionStatus.approve:
        return {
          icon: <ApprovedIcon />,
          title: "חיוב",
          color: "#18CB75",
        };
      case transactionStatus.cancel:
        return {
          icon: <CancelledIcon />,
          title: "זיכוי",
          color: "#BFBFE7",
        };

      default:
        return undefined;
    }
  };

  return (
    <div className="home-table-row">
      <div className={cn("home-table-row__title transaction-status", {
        "cancelled": status === transactionStatus.cancel 
      })}>
        {getStatusDetails()?.title}
        {getStatusDetails()?.icon}
      </div>
      <div className="home-table-row__title transaction-id">{id}</div>
      <div className="home-table-row__title product-name">{name}</div>
      <div className="home-table-row__title sum">{`${sum}₪`}</div>
      <div className="home-table-row__title transaction-date">
        {getFormattedDate(date)}
      </div>
      <div className="home-table-row__title status" />
      <div
        className="home-table-row__border"
        style={{ backgroundColor: getStatusDetails()?.color }}
      ></div>
    </div>
  );
};

export default TableRow;
