import { useQuery } from "react-query";
import { QueryKey } from "src/constants/query";
import { Home } from "src/services/Home";
import "./index.scss";
import TableRow from "./TableRow";
import ClipLoader from "react-spinners/ClipLoader";
import { ReactComponent as EmptyTransactions } from "../../assets/icons/empty-transactions.svg";

const HomeTable = () => {
  const businessesTransactions = useQuery(
    [QueryKey.BUSINESSES_TRANSACTIONS],
    () => {
      return Home.getBusinessesTransactions();
    }
  );

  return (
    <div className="home-table">
      <div className="home-table__header">
        <div className="home-table__header-title transaction-status">
          סטטוס עסקה
        </div>
        <div className="home-table__header-title transaction-id">מזהה עסקה</div>
        <div className="home-table__header-title product-name">שם המוצר</div>
        <div className="home-table__header-title sum">סכום</div>
        <div className="home-table__header-title transaction-date">
          תאריך עסקה
        </div>
        <div className="home-table__header-title status" />
      </div>

      {businessesTransactions.isLoading ? (
        <div className="home-table__empty-wrapper">
          <ClipLoader
            color="#d354ff"
            loading={true}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : businessesTransactions.data?.length ? (
        businessesTransactions.data?.map((item, index) => {
          return (
            <TableRow
              key={index}
              status={item.payment_status}
              id={item.id}
              name={item.order_details}
              sum={item.payment}
              date={item.payment_date}
            />
          );
        })
      ) : (
        <div className="home-table__empty-wrapper">
          <EmptyTransactions />
          <h3 className="home-table__empty-title"> אין עסקאות אחרונות</h3>
          <h5 className="home-table__empty-subtitle">
            באזור זה תוכל לראות עסקאות אחרונות שבוצעו בבית העסק שלך באמצעות
            אפליקציית יאנגי
          </h5>
        </div>
      )}
    </div>
  );
};

export default HomeTable;
