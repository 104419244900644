import "./index.scss";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { useQuery } from "react-query";
import { QueryKey } from "src/constants/query";
import { Home } from "src/services/Home";

const Header = () => {
  const businessesInfo = useQuery([QueryKey.BUSINESSES_INFO], () => {
    return Home.getBusinessInfo();
  });

  const logout = () => {
    localStorage.removeItem("access_token");
    window.location.reload();
  };

  return (
    <div className="header">
      <div className="header__disconnection" onClick={() => logout()}>
        התנתקות
      </div>
      <div className="header__shop-info">
        <p className="header__shop-ip">
          {`${businessesInfo.data?.H_P} מספר ח.פ`}{" "}
        </p>
        <p className="header__shop-name">| {businessesInfo.data?.name}</p>

        <div className="header__shop-icon"></div>
      </div>
      <Logo />
    </div>
  );
};

export default Header;
