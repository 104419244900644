import { ReactComponent as Discount } from "../../assets/icons/discount.svg";
import { ReactComponent as Accept } from "../../assets/icons/accept.svg";
import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg";
import Timer from "../Timer";

import "./index.scss";
import { useState } from "react";
import { IModalInfo } from "src/models/home";
import TransactionResult from "./TransactionResult";
import { Transaction } from "src/services/Transaction";

interface Props {
  close: () => void;
  cancel: () => void;
  modalInfo: IModalInfo | null;
}

const dateOptions: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
};

const TransactionView: React.FC<Props> = ({ close, cancel, modalInfo }) => {
  const [showResult, setShowResult] = useState<boolean>(false);
  const [details, setDetails] = useState<string>("");

  const getFormattedDate = (date?: string) => {
    if (date) {
      return new Date(date)
        .toLocaleDateString("en-GB", dateOptions)
        .replaceAll(",", "");
    }
    return "";
  };

  const acceptPayment = async () => {
    const paymentDetails = details.length ? details : "אין תיאור";

    const response = await Transaction.acceptPaymentRequest(
      modalInfo?.payment_id,
      paymentDetails
    );

    if (response) {
      setShowResult(true);
    }
  };

  return (
    <>
      {showResult ? (
        <TransactionResult
          close={close}
          result="accept"
          transactionId={modalInfo?.payment_id}
        />
      ) : (
        <>
          <div className="transaction__timer">
            <Timer
              initialMinute={2}
              initialSeconds={0}
              onTimerEnd={() => close()}
            />
            זמן שנותר:
          </div>
          <h3 className="transaction__title">{`${modalInfo?.child_name} אישור עסקה עבור`}</h3>

          <div className="transaction__row">
            <p className="transaction__row-text">
              {getFormattedDate(modalInfo?.new_payment)}
            </p>
            <h5 className="transaction__row-title">תאריך עסקה</h5>
          </div>

          <div className="transaction__row">
            <p className="transaction__row-text">{`${modalInfo?.payment}₪`}</p>
            <h5 className="transaction__row-title">סכום לתשלום</h5>
          </div>

          {modalInfo?.coupon && (
            <div className="transaction__discount">
              נרכש באמצעות קופון
              <Discount />
            </div>
          )}

          <h4 className="transaction__details-title">פרטי המוצר</h4>
          <h6 className="transaction__details-subtitle">
            הכנס את פרטי המוצר שנרכש
          </h6>

          <textarea
            className="transaction__details"
            placeholder="פרטי המוצר..."
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />

          <div className="transaction__buttons">
            <button
              type="button"
              className="transaction__button accept"
              onClick={() => acceptPayment()}
            >
              אישור עסקה
              <Accept />
            </button>
            <button
              type="button"
              className="transaction__button cancel"
              onClick={() => cancel()}
            >
              דחיית עסקה
              <Cancel />
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default TransactionView;
