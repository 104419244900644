import { useState } from "react";
import { IModalInfo } from "src/models/home";
import CancelView from "./CancelView";
import "./index.scss";
import TransactionView from "./TransactionView";

interface Props {
  close: () => void;
  modalInfo: IModalInfo | null;
}

const Transaction: React.FC<Props> = ({ close, modalInfo }) => {
  const [isCancel, setIsCancel] = useState<boolean>(false);


  return (
    <div className="transaction__wrapper" onClick={() => close()}>
      <div className="transaction" onClick={(e) => e.stopPropagation()}>
        {isCancel ? (
          <CancelView
            close={close}
            returnTo={() => setIsCancel(false)}
            paymentId={modalInfo?.payment_id}
          />
        ) : (
          <TransactionView
            close={close}
            cancel={() => setIsCancel(true)}
            modalInfo={modalInfo}
          />
        )}
      </div>
    </div>
  );
};

export default Transaction;
