import { IUser } from "./../../models/user";
import { createReducer } from "@reduxjs/toolkit";
import { setAccessToken, setUserInfo } from "./actions";

export const initialState: IUser = {
  accessToken: null,
  isLogged: false,
  info: null,
};

export const userReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setUserInfo, (state, { payload }) => {
      state.info = payload;
    })
    .addCase(setAccessToken, (state, { payload }) => {
      state.accessToken = payload;
      state.isLogged = true;
    })
);
