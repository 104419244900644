import { useNavigate } from "react-router-dom";
import "./index.scss";

const ResetPasswordPage = () => {
  const navigate = useNavigate();

  return (
    <div className="reset-password">
      <div className="reset-password__content">
        <h2 className="reset-password__title">לאיפוס הסיסמה פנה ליאנגי</h2>
        <p className="reset-password__text">
          לצורך איפוס סיסמתך אנא פנה אלינו בכתובת המייל:
        </p>
        <div className="reset-password__mail">mail@youngi.co.il</div>

        <div className="reset-password__button" onClick={() => navigate(-1)}>
          חזור לעמוד התחברות
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
