import { Navigate } from "react-router-dom";
import { isAuthorized } from "src/helpers/main";

interface Props {
  redirectTo: string;
  children: any;
}

export const ProtectedRoute: React.FC<Props> = ({ children, redirectTo }) => {
  const isLogged = isAuthorized();
  if (!isLogged) {
    return <Navigate to={redirectTo} replace />;
  }

  return children;
};
