import "./index.scss";
import { createPortal } from "react-dom";

import * as yup from "yup";
import { formErrors } from "src/config/form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ISignIn } from "src/models/signIn";
import { useState } from "react";

import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { ReactComponent as EyeClose } from "../../assets/icons/eye-close.svg";
import { User } from "src/services/User";
import { useNavigate } from "react-router-dom";
import LoginError from "./LoginError";

const validation = yup.object({
  email: yup
    .string()
    .email(formErrors.INVALID_EMAIL)
    .required(formErrors.REQUIRED),
  password: yup.string().required(formErrors.REQUIRED),
});

const SignInPage = () => {
  const [loginError, setLoginError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  const form = useForm<ISignIn>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(validation),
  });
  form.watch();

  const login = async () => {
    const response = await User.login(form.getValues());

    if (response.status === 400) {
      setLoginError(true);
    }

    if (response.status === "success") {
      localStorage.setItem("access_token", response.access_token);
      navigate("/home");
    }
  };

  return (
    <div className="sign-in">
      <form className="sign-in__content">
        <h2 className="sign-in__title">ברוכים השבים!</h2>
        <h2 className="sign-in__subtitle">אנא מלאו את הפרטים הבאים להתחברות</h2>

        <div className="sign-in__row">
          <p className="sign-in__label">שם משתמש</p>
          <input
            className="sign-in__input"
            {...form.register("email")}
            placeholder="Almogrozenzohari@gmail.com"
          />
        </div>
        <div className="sign-in__row">
          <p className="sign-in__label">סיסמה</p>
          <div className="sign-in__input--wrapper">
            <input
              className="sign-in__input hebrew"
              {...form.register("password")}
              placeholder="סיסמה"
              type={showPassword ? "text" : "password"}
            />

            <div
              className="sign-in__password-button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeClose /> : <Eye />}
            </div>

            <div
              className="sign-in__forgot-password"
              onClick={() => navigate("/reset-password")}
            >
              שכחתי סיסמה
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="sign-in__button"
          onClick={(e) => {
            e.preventDefault();
            login();
          }}
        >
          התחברות
        </button>
      </form>

      {loginError &&
        createPortal(
          <LoginError close={() => setLoginError(false)} />,
          document.getElementById("modal-root") as Element
        )}
    </div>
  );
};

export default SignInPage;
