import { BusinessesTransactions } from "src/models/home";
import { Api } from "./Api";

export class Home {
  static async getBusinessInfo(): Promise<any> {
    const { data } = await Api.get(`/businesses`);

    return data;
  }

  static async getBusinessesTransactions(): Promise<BusinessesTransactions[]> {
    const { data } = await Api.get(`/businesses/transactions`);

    return data;
  }
}
