import "./index.scss";
import { useState } from "react";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as ArrowReturn } from "../../assets/icons/arrow-return.svg";
import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg";

import cn from "classnames";
import { Transaction } from "src/services/Transaction";
import TransactionResult from "./TransactionResult";

interface Props {
  close: () => void;
  returnTo: () => void;
  paymentId: number | null | undefined;
}

const options = [
  { value: "incorrectAmount", label: "סכום לא נכון" },
  { value: "invalidCoupon", label: "קופון לא בתוקף" },
  { value: "notInterestedInPurchasing", label: "לא מעוניין ברכישה" },
  { value: "other", label: "אחר" },
];

export interface SelectOption<Value> {
  label: string;
  value: Value;
}

const CancelView: React.FC<Props> = ({ close, returnTo, paymentId }) => {
  const [value, setValue] = useState<string>("");
  const [showResult, setShowResult] = useState<boolean>(false);

  const [openDropDown, setOpenDropDown] = useState<boolean>(false);

  const handleChange = (value: string) => {
    setValue(value);
    setOpenDropDown(false);
  };

  const cancelPayment = async () => {
    const response = await Transaction.cancelPaymentRequest(paymentId, value);

    if (response) {
      setShowResult(true);
    }
  };

  return (
    <>
      {showResult ? (
        <TransactionResult
          close={close}
          result="cancel"
          reason={value}
          transactionId={paymentId}
        />
      ) : (
        <>
          <h3 className="transaction-cancel__title">
            סיבת דחיית הזמנה עבור אבי כהן
          </h3>

          <h4 className="transaction-cancel__subtitle">
            חשוב לנו לדעת מדוע דחית את ההזמנה
          </h4>
          <p className="transaction-cancel__text">אנא בחר את אחת האפשרויות </p>

          <div className="select--wrapper">
            <div
              className={cn("select", {
                "select--open": openDropDown,
              })}
              onClick={() => setOpenDropDown(!openDropDown)}
            >
              <div className="select__indicator">
                <ArrowDown />
              </div>
              <input
                className="select__input"
                placeholder="בחר אחת מהאפשרויות"
                readOnly={true}
                value={value}
              />
            </div>
            {openDropDown && (
              <div className="select__dropdown">
                <div className="select__dropdown--separator" />
                <div className="select__options">
                  {options.map((item, index) => {
                    const isFirst = index === 0;
                    return (
                      <div
                        className="select__option"
                        key={index}
                        style={{ marginTop: isFirst ? 8 : 16 }}
                        onClick={() => handleChange(item.label)}
                      >
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <div className="transaction-cancel__buttons">
            <button
              className="transaction-cancel__button cancel-button"
              disabled={value.length === 0}
              onClick={() => cancelPayment()}
            >
              דחיית עסקה
              <Cancel />
            </button>
            <button
              className="transaction-cancel__button return-button"
              onClick={() => returnTo()}
            >
              חזור
              <ArrowReturn />
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default CancelView;
