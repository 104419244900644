import "./index.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import SignInPage from "./SignInPage";
import HomePage from "./HomePage";
import { ProtectedRoute } from "src/routing/ProtectedRoute";
import { isAuthorized } from "src/helpers/main";
import ResetPasswordPage from "./ResetPasswordPage";

function App() {
  const isLogged = isAuthorized();

  return (
    <div className="app">
      <Routes>
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />

        <Route
          path="/home"
          element={
            <ProtectedRoute redirectTo="/sign-in">
              <HomePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="*"
          element={<Navigate to={isLogged ? "/home" : "/sign-in"} replace />}
        />
      </Routes>
    </div>
  );
}

export default App;
