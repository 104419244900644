import "./index.scss";

interface Props {
  close: () => void;
}

const LoginError: React.FC<Props> = ({ close }) => {
  return (
    <div className="login-error__wrapper" onClick={() => close()}>
      <div className="login-error" onClick={(e) => e.stopPropagation()}>
        <h3 className="login-error__title">אימייל או סיסמה שגויים</h3>
        <h6 className="login-error__subtitle">בבקשה נסה שוב</h6>

        <button className="login-error__button" onClick={() => close()}>
          OK
        </button>
      </div>
    </div>
  );
};

export default LoginError;
