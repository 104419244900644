import "./index.scss";
import cn from "classnames";
import { ReactComponent as Cross } from "../../assets/icons/cross.svg";

interface Props {
  close: () => void;
  result: string;
  reason?: string;
  transactionId: number | null | undefined;
}

const TransactionResult: React.FC<Props> = ({
  close,
  result,
  reason,
  transactionId,
}) => {
  return (
    <div className="transaction-result">
      <button type="button" className="transaction-result__cross" onClick={() => close()}>
        <Cross />
      </button>

      <div
        className={cn("transaction-result__circle", {
          "transaction-result__circle-cancel": result === "cancel",
        })}
      >
        <div
          className={cn("transaction-result__circle--inner", {
            "transaction-result__circle--inner-cancel": result === "cancel",
          })}
        />
      </div>

      <h3 className="transaction-result__title">
        {result === "cancel" ? "העסקה נדחתה" : "העסקה אושרה בהצלחה!"}
      </h3>
      {reason && <h5 className="transaction-result__subtitle">{reason}</h5>}

      <div className="transaction-result__id">
        {`מזהה עסקה: ${transactionId}`}
      </div>
    </div>
  );
};

export default TransactionResult;
