import { Api } from "./Api";

export class Transaction {
  static async acceptPaymentRequest(
    paymentId: number | undefined,
    orderDetails: string
  ): Promise<any> {
    const { data } = await Api.patch(`/businesses/accept_payment`, {
      payment_id: paymentId,
      order_details: orderDetails,
    });

    return data;
  }

  static async cancelPaymentRequest(
    paymentId: number | undefined | null,
    reason: string
  ): Promise<any> {
    const { data } = await Api.patch(`/businesses/cancel_payment`, {
      payment_id: paymentId,
      reason,
    });

    return data;
  }
}
