import { IUserLogin } from "./../models/user";
import axios from "axios";
import { API_URL } from "../constants/env";
import { IUserProfileModel } from "./../models/user";

export class User {
  static async login(params: IUserLogin): Promise<any> {
    return axios
      .post(
        `${API_URL}/auth/login`,
        JSON.stringify({
          email: params.email,
          password: params.password,
        }),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  static createFromApi(data: any): IUserProfileModel {
    return {
      name: data.name,
      email: data.email,
      phone: data.phone,
      password: data.password,
      passwordConfirm: data.passwordConfirm,
      role: data.role,
      active: data.active,
      payable: data.payable,
      phoneToken: data.phone_token,
    };
  }
}
